import React, { ReactNode } from 'react';

import CustomInputStyles from '../Deprecated/HomePrice.module.scss';
import style from './DatePicker.module.scss';

import cn from 'classnames';
import isValid from 'date-fns/isValid';
import DatePicker from 'react-datepicker';

// import Label from '../Label';

export interface IDatePickerInputProps {
  label: string;
  name: string;
  className?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  openToDate?: Date;
  isInline?: boolean;
  inputElement?: ReactNode;
  isDateAvailable?: (date: Date) => boolean;
  onChange: (name: string, value: Date) => void;
  showDisabledMonthNavigation?: boolean;
  value: string;
}

class CustomInput extends React.Component<{ label?: string; name: string }> {
  render() {
    const { label = '', name = '', ...restProps } = this.props;

    return (
      <div className={cn(CustomInputStyles.container, CustomInputStyles.datepicker)}>
        <div className={CustomInputStyles.inputWrapper}>
          {/* <Label label={label} htmlFor={name} toolTip="" /> */}
          <div className={CustomInputStyles.inputSymbol}>
            <svg
              width="20px"
              height="22px"
              viewBox="0 0 20 22"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="style-guide" transform="translate(-871.000000, -1902.000000)">
                  <g id="Group-23-Copy" transform="translate(851.000000, 1831.000000)">
                    <g id="Group-7" transform="translate(20.000000, 71.000000)">
                      <rect
                        id="Rectangle"
                        stroke="#333333"
                        strokeWidth="1.7"
                        x="0.85"
                        y="2.85"
                        width="18.3"
                        height="18.3"
                      ></rect>
                      <line
                        x1="14"
                        y1="0"
                        x2="14"
                        y2="5"
                        id="Path"
                        stroke="#333333"
                        strokeWidth="1.6"
                      ></line>
                      <polyline
                        id="Path"
                        stroke="#333333"
                        strokeWidth="1.6"
                        points="6 5 6 0 6 0"
                      ></polyline>
                      <rect
                        id="Rectangle"
                        stroke="#333333"
                        strokeWidth="1.7"
                        x="0.85"
                        y="2.85"
                        width="18.3"
                        height="4.3"
                      ></rect>
                      <text
                        id="1"
                        fontFamily="Helvetica"
                        fontSize="8"
                        fontWeight="normal"
                        fill="#333333"
                      >
                        <tspan x="8" y="17">
                          1
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <input id={name} name={name} {...restProps} />
        </div>
      </div>
    );
  }
}

export const DatePickerInput = function (props: IDatePickerInputProps) {
  const {
    name,
    className,
    placeholder,
    isDateAvailable,
    inputElement,
    isInline,
    ...restProps
  } = props;

  const handleChange = (date: Date) => {
    if (isValid(date)) {
      props.onChange(name, date);
    }
  };

  const selectedDate = props.value ? new Date(props.value) : undefined;

  return (
    <div className={style.datePicker}>
      <DatePicker
        name={name}
        popperClassName={className}
        placeholderText={placeholder}
        selected={selectedDate}
        onChange={handleChange}
        dateFormat="MM-dd-yyyy"
        filterDate={isDateAvailable}
        customInput={inputElement || <CustomInput label={props.label} name={name} />}
        inline={isInline}
        disabledKeyboardNavigation
        showPopperArrow={false}
        autoComplete="off"
        popperModifiers={{
          flip: {
            enabled: false,
            behavior: ['bottom'],
          },
          offset: {
            enabled: true,
            offset: '60px, -402px',
          },
          preventOverflow: {
            enabled: false,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}
        popperPlacement="top-start"
        {...restProps}
      />
    </div>
  );
};
