import React, { Component } from 'react';

import calendarIcon from '../../assets/images/calendarIcon.svg';
import { withFirebase } from '../../utils/Firebase';
import DynamicHeight from '../DynamicHeight';
import { DatePickerInput } from '../Input/Datepicker';
import Loading from '../Loading';

import variables from '../../styles/_helpers/_variables.scss';
import style from './Dashboard.scss';

import cn from 'classnames';
import { addDays, format, isAfter, isBefore, subDays, subMonths, subWeeks } from 'date-fns';
import {
  Area,
  Bar,
  BarChart,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const getLastSunday = () => {
  const date = new Date();
  const today = date.getDate();
  const dayOfTheWeek = date.getDay();
  const newDate = date.setDate(today - (dayOfTheWeek || 7));
  return new Date(newDate);
};

class AdminDashboard extends Component {
  _initFirebase = false;

  state = {
    domains: [],
    loading: true,
  };

  firebaseInit = async () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      // TODO: we can't store more than 10MB at localstorage, and now wmpData is more than 23MB+
      await this.getPosts();

      // const storeDataWithTTL = (data, expirationHours = 12) => {
      //   if (data) {
      //     localStorage.setItem(
      //       'dataTTL',
      //       (new Date().getTime() + expirationHours * 3600000).toString()
      //     );

      //     localStorage.setItem(
      //       'wmpData',
      //       JSON.stringify({
      //         ...data,
      //       })
      //     );
      //   }
      // };

      // const dataTTL = localStorage.getItem('dataTTL');

      // const isStaleData =
      //   !dataTTL || new Date().getTime() - new Date(Number(dataTTL)).getTime() >= 0;

      // if (isStaleData) {
      //   console.log('Retrieving fresh data from database.');
      //   const { domains, users, wmpAllCalculations } = await this.getPosts();
      //   storeDataWithTTL({ domains, users, wmpAllCalculations });
      // } else {
      //   const data = localStorage.getItem('wmpData');

      //   if (data) {
      //     const { domains, users, wmpAllCalculations } = { ...JSON.parse(data) };

      //     console.log(
      //       `Retrieving data from local storage ${
      //         dataTTL
      //           ? `(${
      //               Math.round(
      //                 Math.abs(
      //                   Number(
      //                     (new Date().getTime() - new Date(Number(dataTTL)).getTime()) / 3600000
      //                   )
      //                 ) *
      //                   100 +
      //                   Number.EPSILON
      //               ) / 100
      //             } hrs TTL)`
      //           : ''
      //       }.`
      //     );

      //     const updatedState = this.getUpdatedState({
      //       ...this.state,
      //       domains,
      //       users,
      //       wmpAllCalculations,
      //     });

      //     this.setState({
      //       ...updatedState,
      //       domains,
      //       users,
      //       wmpAllCalculations,
      //       loading: false,
      //     });
      //   }
      // }
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.firebase && prevProps.firebase !== this.props.firebase) {
      await this.firebaseInit();
      const updatedState = this.getUpdatedState(this.state);

      this.setState({
        ...updatedState,
      });
    }
  }

  getPosts = async () => {
    const { secondary } = this.props.firebase;

    const whiteList = await this.props.firebase
      .whitelist()
      .once('value')
      .then((querySnapshot) => querySnapshot.val())
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    const blackList = await this.props.firebase
      .blacklist()
      .once('value')
      .then((querySnapshot) => querySnapshot.val())
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    const wmpUsers = await secondary
      .users()
      .once('value')
      .then((querySnapshot) => querySnapshot.val())
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    const wmpAllCalculations = await secondary
      .calculations()
      .once('value')
      .then((querySnapshot) => querySnapshot.val())
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    const domains = { ...whiteList, ...blackList };

    if (whiteList && blackList) {
      const updatedState = this.getUpdatedState({ ...this.state, domains });

      this.setState({
        ...updatedState,
        domains,
        users: wmpUsers,
        wmpAllCalculations,
        loading: false,
      });

      return {
        domains,
        users: wmpUsers,
        wmpAllCalculations,
      };
    }
  };

  getUpdatedState = (state) => {
    const { domains, users, wmpAllCalculations } = state;

    const domainOptions = [
      { label: '', value: '' },
      ...Object.keys(domains || {}).map((key) => {
        if (domains[key].domain) return { label: domains[key].domain, value: domains[key].domain };
      }),
    ].filter((el) => el && el);

    const instanceData = Object.keys(domains || {}).reduce(
      (acc, domain) => {
        const currentPost = domains[domain];

        if (currentPost?.instances) {
          Object.values(currentPost.instances || {}).map((instance) => {
            const { url, date, location } = instance;

            const domain = url?.host;

            if (
              instance.date &&
              (!state.startDate || isAfter(instance?.date, state.startDate)) &&
              (!state.endDate || isBefore(instance?.date, addDays(state.endDate, 1))) &&
              (!state.domain || domain === state.domain) &&
              (!state.state || location?.region === state.state) &&
              (!state.country || location?.country === state.country)
            ) {
              acc.total += 1;

              const formattedDate = `${new Date(date).setHours(0, 0, 0, 0)}`;

              acc.domainCount = {
                ...acc.domainCount,
                [domain]: (acc.domainCount[domain] || 0) + 1,
              };

              acc.topPages = {
                ...acc.topPages,
                [domain]: {
                  [url.pathname]:
                    ((acc.topPages[domain] && acc.topPages[domain][url.pathname]) || 0) + 1,
                },
              };

              acc.instances = {
                ...acc.instances,
                [formattedDate]: {
                  ...acc.instances[formattedDate],
                  count: (acc.instances[formattedDate]?.count || 0) + 1,
                },
              };

              if (location) {
                const {
                  country,
                  region,
                  // city
                } = location;

                acc.location = {
                  ...acc.location,
                  State: {
                    ...acc.location.State,
                    [region]: ((acc.location?.State && acc.location?.State[region]) || 0) + 1,
                  },
                  Country: {
                    ...acc.location.Country,
                    [country]: ((acc.location?.Country && acc.location?.Country[country]) || 0) + 1,
                  },
                };
              }
            }
          });
        }

        return acc;
      },
      {
        total: 0,
        instances: {},
        location: {},
        domainCount: {},
        topPages: {},
      }
    );

    const calculationData = Object.keys(wmpAllCalculations || {}).reduce(
      (acc, calculation) => {
        const { date, values } = wmpAllCalculations[calculation];

        const { currentTab } = values;

        if (
          date &&
          (!state.startDate || isAfter(date, state.startDate)) &&
          (!state.endDate || isBefore(date, addDays(state.endDate, 1)))
        ) {
          const formattedDate = `${new Date(date).setHours(0, 0, 0, 0)}`;

          acc.total += 1;
          acc.calculations[formattedDate] = (acc.calculations[formattedDate] || 0) + 1;
          acc.calculatorTypes[currentTab] = (acc.calculatorTypes[currentTab] || 0) + 1;
        }

        return acc;
      },
      {
        total: 0,
        calculations: {},
        calculatorTypes: {},
      }
    );

    const wmpUsernData = Object.keys(users || {}).reduce(
      (acc, user) => {
        const { type, state: userState } = users[user];

        if (!state.state || userState === state.state) {
          acc.total += 1;
          acc.userType[type] = (acc.userType[type] || 0) + 1;
          acc.userState[userState] = (acc.userState[userState] || 0) + 1;
        }

        return acc;
      },
      {
        total: 0,
        userType: {},
        userState: {},
      }
    );

    const sortedInstanceData = Object.fromEntries(
      Object.entries(instanceData.instances || {}).sort(([a], [b]) => a - b)
    );

    const formattedInstanceData = Object.entries(sortedInstanceData || {}).reduce(
      (acc, [date, instances]) => [
        ...acc,
        {
          date: new Date(Number(date)),
          ...instances,
        },
      ],
      []
    );

    const formattedCalculationData = Object.entries(calculationData?.calculations || {}).reduce(
      (acc, [date, count]) => {
        return [...acc, { date: new Date(Number(date)), count }];
      },
      []
    );

    const wmpCalculatorTypesData = Object.entries(calculationData?.calculatorTypes || {}).reduce(
      (acc, [name, count]) => {
        return [...acc, { name, count }];
      },
      []
    );

    const formattedWMPUserTypeData = Object.entries(wmpUsernData?.userType || {}).reduce(
      (acc, [name, count]) => {
        return [...acc, { name, count }];
      },
      []
    );

    const formattedWMPUserStateData = Object.entries(wmpUsernData?.userState || {}).reduce(
      (acc, [name, count]) => {
        return [...acc, { name, count }];
      },
      []
    );

    const sortedTopDomains = Object.fromEntries(
      Object.entries(instanceData.domainCount || {}).sort(
        ([a], [b]) => instanceData.domainCount[b] - instanceData.domainCount[a]
      )
    );

    const topDomains = Object.entries(sortedTopDomains || {})
      .reduce((acc, [name, count]) => [...acc, { name, count }], [])
      .slice(0, 10);

    const topPages =
      instanceData.topPages[state.domain] &&
      Object.entries(instanceData.topPages[state.domain])
        .reduce((acc, [name, count]) => [...acc, { name, count }], [])
        .slice(0, 5);

    const totalWidgetInstances = Object.values(instanceData?.instances || {}).reduce(
      (acc, domain) => (acc += domain.count),
      0
    );

    const totalUserCalculations = Object.values(users || {}).reduce(
      (acc, user) => (acc += Object.keys(user.calculations || {}).length),
      0
    );

    const usersWithCalculations = Object.values(users || {}).reduce(
      (acc, user) => (acc += user.calculations ? 1 : 0),
      0
    );

    return {
      domainOptions: domainOptions || [],
      sortedTopDomains,
      instanceData,
      formattedInstanceData,
      formattedCalculationData,
      topDomains,
      topPages,
      totalWidgetInstances,
      totalUserCalculations,
      totalCalculations: calculationData.total,
      usersWithCalculations,
      calculationData,
      wmpCalculatorTypesData,
      formattedWMPUserTypeData,
      formattedWMPUserStateData,
    };
  };

  handleDateChange = (dates) => {
    const [start, end] = dates;

    const tempValues = {
      ...this.state,
      startDate: start,
      endDate: end,
      quickDate: '',
    };

    const updatedState = this.getUpdatedState(tempValues);

    this.setState({
      ...tempValues,
      ...updatedState,
    });
  };

  handleDomainClick = (e) => {
    if (!e?.activeLabel) {
      return;
    }
    const { activeLabel } = e;

    if (activeLabel !== this.state.domain) {
      const tempValues = {
        ...this.state,
        domain: activeLabel,
      };

      const updatedState = this.getUpdatedState(tempValues);

      this.setState({
        ...tempValues,
        ...updatedState,
      });
    }
  };

  handleQuickDateClick = (e) => {
    const value = e?.target?.dataset?.value;
    if (value) {
      const tempValues = {
        ...this.state,
        quickDate: value,
      };

      tempValues.startDate = this.state.startDate;
      tempValues.endDate = this.state.endDate;

      const today = new Date();

      const lastSunday = getLastSunday();

      const date = today,
        y = date.getFullYear(),
        m = date.getMonth();

      const firstDayOfMonth = new Date(y, m, 1);
      const lastDayOfMonth = new Date(y, m + 1, 0);

      switch (value) {
        case 'today':
          tempValues.startDate = today;
          tempValues.endDate = today;
          break;
        case 'yesterday':
          tempValues.startDate = subDays(today, 1);
          tempValues.endDate = tempValues.startDate;
          break;
        case 'thisWeek':
          tempValues.startDate = lastSunday;
          tempValues.endDate = today;
          break;
        case 'lastWeek':
          tempValues.startDate = subWeeks(lastSunday, 1);
          tempValues.endDate = subDays(lastSunday, 1);
          break;
        case 'thisMonth':
          tempValues.startDate = firstDayOfMonth;
          tempValues.endDate = lastDayOfMonth;
          break;
        case 'lastMonth':
          tempValues.startDate = subMonths(firstDayOfMonth, 1);
          tempValues.endDate = subDays(firstDayOfMonth, 1);
          break;
        case 'last12Months':
          tempValues.startDate = subMonths(today, 12);
          tempValues.endDate = today;
          break;
      }

      const updatedState = this.getUpdatedState(tempValues);

      this.setState({
        ...tempValues,
        ...updatedState,
      });
    }
  };

  handleDateChartClick = (e) => {
    if (!e?.activeLabel) {
      return;
    }

    const { activeLabel } = e;

    if (activeLabel !== this.state.startDate) {
      const tempValues = {
        ...this.state,
        quickDate: '',
        startDate: activeLabel,
        endDate: activeLabel,
      };

      const updatedState = this.getUpdatedState(tempValues);

      this.setState({
        ...tempValues,
        ...updatedState,
      });
    }
  };

  handleStateClick = (e) => {
    if (!e?.activeLabel) {
      return;
    }

    const { activeLabel } = e;

    if (activeLabel !== this.state.state) {
      const tempValues = {
        ...this.state,
        state: activeLabel,
      };

      const updatedState = this.getUpdatedState(tempValues);

      this.setState({
        ...tempValues,
        ...updatedState,
      });
    }
  };

  handleCountryClick = (e) => {
    if (!e?.activeLabel) {
      return;
    }

    const { activeLabel } = e;

    if (activeLabel !== this.state.country) {
      const tempValues = {
        ...this.state,
        country: activeLabel,
      };

      const updatedState = this.getUpdatedState(tempValues);

      this.setState({
        ...tempValues,
        ...updatedState,
      });
    }
  };

  handleClearFilters = () => {
    const tempValues = {
      ...this.state,
      quickDate: '',
      startDate: '',
      endDate: '',
      domain: '',
      state: '',
      country: '',
    };

    const updatedState = this.getUpdatedState(tempValues);

    this.setState({
      ...tempValues,
      ...updatedState,
    });

    this.setState({ ...tempValues, ...updatedState });
  };

  handleClearDomain = () => {
    const tempValues = {
      ...this.state,
      domain: '',
    };

    const updatedState = this.getUpdatedState(tempValues);

    this.setState({
      ...tempValues,
      ...updatedState,
    });

    this.setState({ ...tempValues, ...updatedState });
  };

  handleClearDate = () => {
    const tempValues = {
      ...this.state,
      quickDate: '',
      startDate: '',
      endDate: '',
    };

    const updatedState = this.getUpdatedState(tempValues);

    this.setState({
      ...tempValues,
      ...updatedState,
    });

    this.setState({ ...tempValues, ...updatedState });
  };

  handleClearState = () => {
    const tempValues = {
      ...this.state,
      state: '',
    };

    const updatedState = this.getUpdatedState(tempValues);

    this.setState({
      ...tempValues,
      ...updatedState,
    });

    this.setState({ ...tempValues, ...updatedState });
  };

  handleClearCountry = () => {
    const tempValues = {
      ...this.state,
      country: '',
    };

    const updatedState = this.getUpdatedState(tempValues);

    this.setState({
      ...tempValues,
      ...updatedState,
    });

    this.setState({ ...tempValues, ...updatedState });
  };

  render() {
    const {
      loading,
      instanceData,
      topDomains,
      topPages,
      formattedInstanceData,
      sortedTopDomains,
    } = this.state;

    if (loading) return <Loading />;

    const { primaryColor } = variables;

    const filtersShown = [
      !!this.state.domain,
      !!this.state.startDate,
      !!this.state.state,
      !!this.state.country,
    ].filter((filter) => !!filter);

    const isFiltersShown = filtersShown.length > 0;

    return (
      <div className={style.container}>
        <div className="row">
          <div className={`col-12 ${style.filters}`}>
            <div className={style.right}>
              <DatePickerInput
                label="date"
                name="date"
                customInput={<img className={style.filterDate} src={calendarIcon} />}
                onChange={this.handleDateChange}
                selectsRange
                selected={this.state.startDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                shouldCloseOnSelect={false}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '-518px, 50px',
                  },
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              >
                <div className={style.calendarButtons}>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'today',
                    })}
                    data-value="today"
                    onClick={this.handleQuickDateClick}
                  >
                    Today
                  </button>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'yesterday',
                    })}
                    data-value="yesterday"
                    onClick={this.handleQuickDateClick}
                  >
                    Yesterday
                  </button>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'thisWeek',
                    })}
                    data-value="thisWeek"
                    onClick={this.handleQuickDateClick}
                  >
                    This Week
                  </button>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'lastWeek',
                    })}
                    data-value="lastWeek"
                    onClick={this.handleQuickDateClick}
                  >
                    Last Week
                  </button>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'thisMonth',
                    })}
                    data-value="thisMonth"
                    onClick={this.handleQuickDateClick}
                  >
                    This Month
                  </button>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'lastMonth',
                    })}
                    data-value="lastMonth"
                    onClick={this.handleQuickDateClick}
                  >
                    Last Month
                  </button>
                  <button
                    className={cn(style.button, {
                      [style.isActive]: this.state.quickDate === 'last12Months',
                    })}
                    data-value="last12Months"
                    onClick={this.handleQuickDateClick}
                  >
                    Last 12 Months
                  </button>
                </div>
              </DatePickerInput>
            </div>
            <DynamicHeight className={style.left} isExpanded={isFiltersShown}>
              {this.state.domain && (
                <p onClick={this.handleClearDomain}>{`${this.state.domain}`}</p>
              )}
              {this.state.startDate && (
                <p onClick={this.handleClearDate}>
                  {`${format(this.state.startDate, 'LLL do, y')}`}
                  {this.state.endDate &&
                    this.state.endDate !== this.state.startDate &&
                    ` - ${format(this.state.endDate, 'LLL do, y')}`}
                </p>
              )}
              {this.state.state && <p onClick={this.handleClearState}>{`${this.state.state}`}</p>}
              {this.state.country && (
                <p onClick={this.handleClearCountry}>{`${this.state.country}`}</p>
              )}
              {filtersShown.length > 1 && <p onClick={this.handleClearFilters}>Clear All</p>}
            </DynamicHeight>
          </div>
          <div className="col-12">
            <h1>Widget Statistics</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <h2>Total domains:</h2>
            <h3> {Object.keys(sortedTopDomains || {}).length}</h3>
          </div>
          <div className="col-3">
            <h2>Total instances:</h2>
            <h3>
              {this.state.totalWidgetInstances?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </h3>
          </div>
        </div>
        <div className={`row ${style.graphOutput}`}>
          {formattedInstanceData && Object.keys(formattedInstanceData || {}).length > 0 ? (
            <>
              <div className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                <h2>Total instances</h2>
                <ResponsiveContainer>
                  <ComposedChart
                    data={formattedInstanceData}
                    margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                    onClick={this.handleDateChartClick}
                  >
                    <XAxis
                      dataKey="date"
                      minTickGap={60}
                      tickFormatter={(value) => format(value, 'Y')}
                    />
                    <YAxis />
                    <Tooltip labelFormatter={(value) => `${format(value, 'LLL do, y')}`} />
                    <Area
                      dataKey="count"
                      fill={primaryColor}
                      stroke={primaryColor}
                      fillOpacity="1"
                      type="basis"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>

              <div className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                {this.state.domain ? <h2>Top 5 pages</h2> : <h2>Top 10 Domains</h2>}
                <ResponsiveContainer>
                  <BarChart
                    margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                    data={this.state.domain ? topPages : topDomains}
                    onClick={this.state.domain ? undefined : this.handleDomainClick}
                  >
                    <YAxis />
                    <Tooltip />
                    <XAxis dataKey="name" />
                    <Bar dataKey="count" fill={primaryColor} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              {Object.entries(instanceData.location || {}).map(([name, entry], index) => (
                <div key={index} className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                  <h2>{name}</h2>
                  <ResponsiveContainer>
                    <BarChart
                      margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                      data={Object.entries(entry || {}).reduce(
                        (acc, [name, count]) => [...acc, { name, count }],
                        []
                      )}
                      onClick={this[`handle${name}Click`]}
                    >
                      <YAxis />
                      <Tooltip />
                      <XAxis dataKey="name" />
                      <Bar dataKey="count" fill={primaryColor} />
                    </BarChart>
                  </ResponsiveContainer>
                  <p>{JSON.stringify(instanceData.location[entry], null, 2)}</p>
                </div>
              ))}
            </>
          ) : (
            <div className="col-12">
              <p className={style.error}>
                No relevant data found. Please try adjusting your filters.
              </p>
            </div>
          )}
        </div>
        <div className={style.divider}></div>
        <div className="row">
          <div className="col-12">
            <h1>Whatsmypayment.com Users</h1>
          </div>
          <div className="col-3">
            <h2>Total registered:</h2>
            <h3> {Object.keys(this.state.users || {}).length}</h3>
          </div>

          <div className="col-3">
            <h2>Users with calculations:</h2>
            <h3>
              {this.state.usersWithCalculations}
              <span>
                {` / ${Math.round(
                  (this.state.usersWithCalculations / Object.keys(this.state.users || {}).length) *
                    100
                )}%`}
              </span>
            </h3>
          </div>
          <div className="col-3">
            <h2>User calculations:</h2>
            <h3>
              {this.state.totalUserCalculations}
              <span>
                {` / ${Math.round(
                  (this.state.totalUserCalculations / this.state.totalCalculations) * 100
                )}%`}
              </span>
            </h3>
          </div>
          <div className="col-3">
            <h2>Total calculations:</h2>
            <h3>{this.state.totalCalculations}</h3>
          </div>
        </div>
        <div className={`row ${style.graphOutput}`}>
          {Object.keys(this.state.formattedCalculationData || {}).length > 0 ? (
            <>
              <div className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                <h2>Total calculations</h2>
                <ResponsiveContainer>
                  <ComposedChart
                    data={this.state.formattedCalculationData}
                    margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                    onClick={this.handleDateChartClick}
                  >
                    <XAxis
                      dataKey="date"
                      minTickGap={60}
                      tickFormatter={(value) => format(value, 'Y')}
                    />
                    <YAxis />
                    <Tooltip labelFormatter={(value) => `${format(value, 'LLL do, y')}`} />
                    <Area
                      dataKey="count"
                      fill={primaryColor}
                      stroke={primaryColor}
                      fillOpacity="1"
                      type="basis"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <div className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                <h2>User Types</h2>
                <ResponsiveContainer>
                  <BarChart
                    margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                    data={this.state.formattedWMPUserTypeData}
                  >
                    <YAxis />
                    <Tooltip />
                    <XAxis dataKey="name" />
                    <Bar dataKey="count" fill={primaryColor} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                <h2>User State</h2>
                <ResponsiveContainer>
                  <BarChart
                    margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                    data={this.state.formattedWMPUserStateData}
                    onClick={this.handleStateClick}
                  >
                    <YAxis />
                    <Tooltip />
                    <XAxis dataKey="name" />
                    <Bar dataKey="count" fill={primaryColor} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-12 col-md-6 col-xl-3" style={{ height: '350px' }}>
                <h2>Calculator Types</h2>
                <ResponsiveContainer>
                  <BarChart
                    margin={{ left: 0, right: 0, top: 0, bottom: 100 }}
                    data={this.state.wmpCalculatorTypesData}
                  >
                    <YAxis />
                    <Tooltip />
                    <XAxis dataKey="name" />
                    <Bar dataKey="count" fill={primaryColor} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <div className="col-12">
              <p className={style.error}>
                No relevant data found. Please try adjusting your filters.
              </p>
            </div>
          )}
        </div>
        <div className={style.divider}></div>
        <div className="row">
          <div className="col-12">
            <h1>WMP User Table</h1>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>First</th>
                  <th>Last</th>
                  <th>Type</th>
                  <th>Email</th>
                  <th>Home Price</th>
                  <th>Loan Term</th>
                  <th>State</th>
                  <th>County</th>
                  <th>Calculations</th>
                </tr>
              </thead>
              <tbody>
                {this.state.users ? (
                  Object.values(this.state.users || {}).map((user, index) => (
                    <tr key={index}>
                      <td>{user.date && `${format(new Date(Number(user.date)), 'LLL do, y')}`}</td>
                      <td title={user.id}>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.type}</td>
                      <td>{user.email}</td>
                      <td>{user.homePrice}</td>
                      <td>{user.loanTerm}</td>
                      <td>{user.state}</td>
                      <td>{user.county}</td>
                      <td>{Object.keys(user.calculations || {}).length}</td>
                    </tr>
                  ))
                ) : (
                  <td>No users found.</td>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style.divider}></div>
      </div>
    );
  }
}

export default withFirebase(AdminDashboard);
